<template>
  <el-select
    v-model="v"
    filterable
    default-first-option
    remote
    :placeholder="placeholder?placeholder:'选择品牌'"
    :size="size"
    clearable
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script>

export default {
  name: 'SelectBrand',
  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'size', 'placeholder'],
  data() {
    return {
      v: null,
      options: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(_) {
        this.v = _
        this.updateData(_)
      }
    },
    v(_) {
      this.updateData(_)
    }
  },
  created() {
    this.getData()
  },
  methods: {
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.value === _
      })
      this.$emit('update:label', obj ? obj.label : null)
      this.$emit('update:value', _)
      this.$nextTick(() => {
        this.$emit('change')
      })
    },
    async getData() {
      this.options = []
      let { options: data } = await this.$api.getBrandOptions()
      if (Array.isArray(data)) {
        this.options = data.map(_ => {
          return { label: _.name, value: _.id }
        })
      }
      this.updateData(this.v)
    }
  }
}
</script>

<style scoped>

</style>
